import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import PaybackIcon from "../../images/icons/payback.svg";
import ZeropcIcon from "../../images/icons/zero_pc.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { ApplicantStage } from "../../utils/applicationStage";

function SelectEmi() {
  const navigate = useNavigate();
  const location = useLocation();
  console.log("this is location from select -emi",location)

  // const [products, setProducts] = useState<
  //   {
  //     productId: string;
  //     tenure: number;
  //     emi: number;
  //   }[]
  // >([]);

  const [product, setProduct] = useState<{
    id?: number;
    applicationId?: string;
    fundCode?: string;
    productId?: string;
    loanAmount?: number;
    emi?: number;
    emiFirstDate?: string;
    dayOfEmi?: number;
    sanctionLetterUrl?: string;
    productDetails?: any;
  }>({});

  useEffect(() => {

    const getProductsDetails = async () => {
      try {
        const applicationId =  location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/sanction-details?applicationId=${applicationId}`
        );
        const result = await response.json();
        console.log(result);
        setProduct(result.data);

        const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
          method: "POST",
          body: JSON.stringify({
            memberId: location?.state?.data.userId,
            // memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        });

        const result1 = await response1.json();
        const authToken = result1.data;

        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/products/id?productId=${result.data.productId}`,
          {
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result2 = await response2.json();
        console.log(result2);

        setProduct((prev) => ({
          ...prev,
          productDetails: result2.data,
        }));
      } catch (error) {
        console.log(error);
      }
    };

    getProductsDetails();

    // getProducts({
    //   userId: location?.state?.data?.userId,
    //   applicationId: location?.state?.data?.applicationId,
    // })
    //   .then((result) => {
    //     console.log(result);
    //     // setProducts(result);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }, [location]);

  useEffect(() => {
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              // navigate("/select-emi", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                flex: 1,
                marginLeft: "1em",
              }}
            >
              <p
                style={{
                  color: "#606060",
                  fontSize: "0.8em",
                  fontWeight:'bold'
                }}
              >
                Principal amount
              </p>
              <p
                style={{
                  fontWeight: 600,
                  fontSize: "1em",
                }}
              >
                ₹ {product?.loanAmount}
              </p>
            </div>
            <div className={styles.advert}>
              {/* <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={ZeropcIcon}
                    alt=""
                    style={{
                      height: "3rem",
                    }}
                  />
                </div>
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: "1em",
                    }}
                  >
                    Processing Fee
                  </p>
                </div>
              </div> */}
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={ZeropcIcon}
                    alt=""

                    className= {styles.firstimages}
                    
                  />
                </div>
                <div>
                  <p className= {styles.prinicpalAmountText}
                    
                  >
                    Foreclosure Charges
                  </p>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  gap: "1rem",
                  alignItems: "center",
                }}
              >
                <div>
                  <img
                    src={PaybackIcon}
                    alt=""
                    
                    className= {styles.images}
                  />
                </div>
                <div>
                  <p className= {styles.prinicpalAmountText}
                    
                  >
                    Pay Back Anytime
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.content}>
            <p
              style={{
                fontWeight: 600,
                fontSize: "1em",
              }}
            >
              Your EMI plan
            </p>
            <div
              style={{
                marginLeft: "20px",
                marginBottom: "10px",
                borderRadius: "10px",
                // border:
                //   selected === productId
                //     ? "1px solid #D32028"
                //     : "1px solid var(--primary-border-dark)",
                background: "#FFF",
                // boxShadow:
                //   selected === productId
                //     ? "0px 3px 3px 0px rgba(211, 32, 40, 0.10)"
                //     : "0px 3px 3px 0px rgba(211, 32, 40, 0.10)",
                height: "8em",
                width: "18em",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1.3em",
                  textAlign: "center",
                  // color: selected === productId ? "#000000" : "#575757",
                }}
              >
                ₹ {product?.emi}
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1em",
                  textAlign: "center",
                  // color: selected === productId ? "#000000" : "#575757",
                }}
              >
                {product?.productDetails?.tenure} months
              </p>
              <p
                style={{
                  fontWeight: "bold",
                  fontSize: "1em",
                  textAlign: "center",
                  // color: selected === productId ? "#000000" : "#575757",
                }}
              >
                Start Date: {moment(product?.emiFirstDate).format("DD/MM/YYYY")}
              </p>
            </div>
            {/*   <div className={styles.planBox}>
             {products?.map(({ tenure, emi, productId }) => (
                <div
                  onClick={() => {
                    setSelected(productId);
                  }}
                  id={productId}
                  style={{
                    marginLeft: "20px",
                    marginBottom: "10px",
                    borderRadius: "12px",
                    border:
                      selected === productId
                        ? "1px solid #D32028"
                        : "1px solid var(--primary-border-dark)",
                    background: "#FFF",
                    boxShadow:
                      selected === productId
                        ? "0px 3px 3px 0px rgba(211, 32, 40, 0.10)"
                        : "0px 3px 3px 0px rgba(211, 32, 40, 0.10)",
                    height: "12em",
                    width: "12em",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                >
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.3em",
                      color: selected === productId ? "#000000" : "#575757",
                    }}
                  >
                    ₹ {emi}
                  </p>
                  <p
                    style={{
                      fontWeight: "bold",
                      fontSize: "1.2em",
                      color: selected === productId ? "#000000" : "#575757",
                    }}
                  >
                    {tenure} months
                  </p>
                </div>
              ))} 
            </div>*/}
          </div>
          <div className={styles.content}>
            <p>Sanction Letter: </p>
            <p
              className={styles.downloadButton}
              onClick={() => {
                fetch(
                  process.env.REACT_APP_DASHBOARD_URL + "/file/url-content-sl?path=" +
                    product?.sanctionLetterUrl?.split("amazonaws.com/")[1]
                )
                  .then((response) => response.json())
                  .then((data) => {
                    // Download the base64 to pdf
                    const linkSource = `${data.data}`;
                    const downloadLink = document.createElement("a");
                    const fileName = "sanction-letter.pdf";
                    downloadLink.href = linkSource;
                    downloadLink.download = fileName;
                    downloadLink.click();
                  });
              }}
            >
              Download
            </p>
          </div>
          <Button insureFin={location?.state?.data?.channelId === 5}
            onPress={() => {
              // navigate("/select-date", {
              //   state: {
              //     selected,
              //     data: products.filter(
              //       ({ productId }) => productId === selected
              //     )[0],
              //   },
              // });
              navigate("/sanctions", {
                state: {
                  data: { product, ...location?.state?.data },
                },
              });
            }}
            text={"Proceed"}
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default SelectEmi;
