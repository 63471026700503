import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import IconIncomeDetails from "../../../images/icons/income_details.svg";
import BankIcon from "../../../images/static_assests/bank.svg";
import PdfIcon from "../../../images/static_assests/pdf.svg";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import LoanStepCard from "../../../components/molecules/Card";
import InputText from "../../../components/atoms/InputText";
import Button from "../../../components/atoms/Button";
import Label from "../../../components/atoms/Label";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getApproval } from "../../../services/application";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import Dropzone, { useDropzone } from "react-dropzone";
import { BANK_LIST } from "../../../utils/banks_list";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { bankStatementPdfUpload } from "../../../services/aa";
import { ApplicantStage } from "../../../utils/applicationStage";
import { Modal, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  assignInsurfinFundSource,
  evaluateInsurfinApplication,
} from "../../../services/riskEngine";









const  BankStatementUpload = () => {
  const [newAuthToken, setNewAuthToken] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);

  const [bankStatementAnalysis,setBankStatementAnalysis] = useState("")

  const bankstatementState = ["uploading","assigningFundSource","evaluating"]

  

  const handleClose = () => {
    setOpen(false);
    setError("");
  };
  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      accept: {
        "application/pdf": [],
      },
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
          setError("Only PDF files are allowed");
          setOpen(true);
        } else {
          setSuccessMessage("");
          setSelectedFile(acceptedFiles);
        }
      },
    });

  const location = useLocation();
  console.log("decode", location?.state?.data);

  const [fipList, setFipList] = useState<
    {
      bank: String;
      health_up: Boolean;
      updated_at?: String;
      issue_details?: String | null;
    }[]
  >([]);

  // const { userId, applicationId } = useContext(DataContext) as DataContextType;

  const [selectedBank, setSelectedBank] = useState("");
  const [searchText, setSearchText] = useState("");


  const [password, setPassword] = useState("");

  const navigate = useNavigate();

  const [bankDialog, setBankDialog] = useState(false);
  const [aaDialog, setAADialog] = useState(false);
  const [selectedMethod, setselectedMethod] = useState("");
  const [bankUplodDialog, setBankUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [stage, setStage] = useState(null);
  const [loading, setLoading] = useState(false);

 

  const [userData, setUserData] = useState({
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
  })


  useEffect(() => {
    setLoading(true);
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        console.log(res.data, 'bank upload')
        //setStage(res.data.skipStatement);
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
           
            case ApplicantStage.PanVerification:
              navigate("/enter-pan", {
                state: { data: location?.state?.data },
              });
              break;
            
            case ApplicantStage.EmploymentDetails:
              // navigate("/identify-yourself",{
              //   state: { data: location?.state?.data ,stage: res.data.skipStatement},
              // });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              
              break;

              // case (ApplicantStage.BankStatementAnaylzation && res?.data?.skipStatement === true):
              //   navigate("/bankdetails-validations", {
              //     state: { data: location?.state?.data },
              //   });
                
              //   break;

              //   case (ApplicantStage.BankStatementAnaylzation && res?.data?.skipStatement === false):
              //   navigate("/bankstatement-anaylsis", {
              //     state: { data: location?.state?.data },
              //   });
                
              //   break;

              
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/pan-details/:data`);
                
                
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);


  
  async function BankAccountVerifiedStatusApi() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${newAuthToken}`);
  
    try {
      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/bank-account-verification/verified-status?userId=${userData.userId}&applicationId=${userData.applicationId}`, 
        {
          method: 'GET',
          headers: myHeaders,
        }
      );
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const res = await response.json();
      return res
      
      
  
    } catch (error) {
      console.error("Error fetching user verified status:", error);
    }
  }
  




  const submitUpload = async () => {
    setBankStatementAnalysis(bankstatementState[0])
    const data = new FormData();
    if (selectedFile && selectedFile[0]) {
      data.append("file", selectedFile[0]);
    }

    data.append("userId", location?.state?.data?.userId);
    // data.append("userId", userData.userId);
    
    data.append("type", "pdf");
    data.append("applicationId",  location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application);
    // data.append("applicationId", userData.applicationId);
    data.append("password", password);

    console.log(data);
    console.log(data.keys());

    const result = await bankStatementPdfUpload(data);
    console.log("result from bank statement",result)
    console.log(result.message);
    if(location?.state?.data?.channelId === 5){
      if (result.message === "success") {
        try {
          console.log("here1")
  
          const response = await BankAccountVerifiedStatusApi()


  
          if (response.status === 1) {
            navigate("/bankstatement-evaluation", {
              state: { data: location?.state?.data },
            });
    
          } 
          
          if (response.status === 0) {
  
            navigate("/bankdetails-info", {
              state: { data: location?.state?.data },
            });
    
          }
  
  
  
          
          
  
          {/* assign fund source and evaluate code */}
  
          {/*
          
          if(userData.channelId !== 3){
            setBankStatementAnalysis(bankstatementState[1])
            const responseFundSource = await assignInsurfinFundSource(
              newAuthToken,
              JSON.stringify({
                //applicationId: location?.state?.data?.applicationId,
                applicationId: userData?.applicationId,
                fundCode: "GBP",
                //loanAmount: location?.state?.data?.fees,
                loanAmount: userData?.fees
              })
            );
    
            console.log(responseFundSource);
  
            
  
          }
  
  
          setTimeout(async () => {
            console.log("here2")
            setLoading(true);
            setBankStatementAnalysis(bankstatementState[2])
            const responseEvaluate = await evaluateInsurfinApplication(
              newAuthToken,
              JSON.stringify({
                //applicationId: location?.state?.data?.applicationId,
                //userId: location?.state?.data?.userId,
  
                applicationId: userData?.applicationId,
                userId: userData?.userId,
              })
            );
      
            console.log("this is evalute response",responseEvaluate);
            setLoading(false);
            setSuccessMessage("")
            
            if (responseEvaluate.data === "green") {
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
            } else if (responseEvaluate.data === "red") {
              navigate("/parking-page-rejected", {
                state: { data: location?.state?.data },
              });
            }else if (responseEvaluate.data === "orange") {
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
            }
          }, 5000);
  
          */}
  
  
        } catch (error) {
          console.log("here3")
          console.log(error);
  
          navigate("/parking-page", {
            state: { data: location?.state?.data },
          });
        }
  
        // navigate("/parking-page", {
        //   state: { data: location?.state?.data },
        // });
      } else {
        console.log("here4")
        setError(result.message);
        setSuccessMessage("");
        setSelectedFile(null);
      
        // navigate("/parking-page", {
        //   state: { data: location?.state?.data },
        // });
      }
    }else{
      navigate("/view-offers", {
        state: { data: location?.state?.data },
      });
      // navigate("/bankstatement-evaluation", {
      //   state: { data: location?.state?.data },
      // });
    }
   
  };

console.log("errorr",error)


  useEffect(() => {
    const authenticate = () => {
      fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/get-active-banks`
      )
        .then((response) => response.json())
        .then((result) => {
          if (result?.data) {
            setFipList(result?.data?.banks);
          }
        })
        .catch((error) => {
          console.log("error", error);
        });
    };

    authenticate();
  }, []);

  const getAARedirectLink = async () => {
    try {
      const authTokenResponse = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            //memberId: location?.state?.data?.userId,
            memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
        }
      );

      const authTokenResult = await authTokenResponse.json();

      console.log(authTokenResult);

      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${authTokenResult.data}`);

      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/account-aggregator/finbox`,
        {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
          body: JSON.stringify({
            bank: selectedBank,
            redirectUrl: `https://feemonk.com/parking-page`,
            // redirectUrl: `http://localhost:3002/parking-page`,
          }),
        }
      );

      const result = await response.json();

      console.log(result.data.url);

      window.open(result.data.url, "_self", "noopener,noreferrer");
    } catch (error) {
      console.log("Error:", error);
    }
  };



  useEffect(() => {
    const getDetails = async () => {
      const response1 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          body: JSON.stringify({
            memberId: location?.state?.data.userId,
            // memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (!response1.ok) {
        throw new Error('Failed to fetch auth data');
      }

      const result1 = await response1.json();

      if (!result1 || !result1.data) {
        throw new Error('Invalid response data');
      }
      const authToken = result1.data;
      setNewAuthToken(authToken);
      const token = sessionStorage.getItem("data")

      if (!token) {
        throw new Error('No token found in session storage');
      }


      // const userDetailsDecoded = JSON.parse(atob(token || ""))
      const userDetailsDecoded = location?.state?.data
      setUserData(userDetailsDecoded)


  


        
          

/* fetching stage */
        setLoading(true);
        const applicationId =  location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        //setStage(res.data.skipStatement);
      })
      .catch((err) => console.log(err));
      setLoading(false)
      
    };
    getDetails();
  }, []);




  // const submitEmploymentDetails = async () => {
  //   // setLoading(true);
  //   // // setPanDetails(PANzoopData);
   
        
  //   //       try {
            
           
  //   //         if(location?.state?.data?.channelId !== 3){
  //   //           const responseFundSource = await assignInsurfinFundSource(
  //   //             newAuthToken,
  //   //             JSON.stringify({
  //   //               applicationId: location?.state?.data?.applicationId,
  //   //               fundCode: "GBP",
  //   //               loanAmount: location?.state?.data?.fees,
  //   //             })
  //   //           );
      
  //   //           console.log(responseFundSource);
  //   //         }
              

  //             // if(userData.channelId !== 3){
  //             //   const responseFundSource = await assignInsurfinFundSource(
  //             //     newAuthToken,
  //             //     JSON.stringify({
  //             //       applicationId: userData.applicationId,
  //             //       fundCode: "GBP",
  //             //       loanAmount: userData.fees,
  //             //     })
  //             //   );
        
  //             //   console.log(responseFundSource);
  //             // }

              
           
    
        
  //             // const responseEvaluate = await evaluateInsurfinApplication(
  //             //   newAuthToken,
  //             //   JSON.stringify({
  //             //     applicationId: location?.state?.data?.applicationId,
  //             //     userId: location?.state?.data?.userId,
  //             //   })
  //             // );
        
  //             // console.log(responseEvaluate);
  //             //   if (responseEvaluate.data === "green") {
  //             //     console.log("this is evaluate green location",location)
  //             //     navigate("/select-emi", {
  //             //       state: { data: location?.state?.data },
  //             //     });
  //             //   } else if (responseEvaluate.data === "red") {
  //             //     navigate("/parking-page-rejected", {
  //             //       state: { data: location?.state?.data },
  //             //     });
  //             //   }else if (responseEvaluate.data === "orange") {
  //             //     navigate("/parking-page", {
  //             //       state: { data: location?.state?.data },
  //             //     });
  //             //   }
              
          
  //         // } catch (error) {
  //         //   console.log(error);
    
  //         //   navigate("/parking-page", {
  //         //     state: { data: location?.state?.data },
  //         //   });
  //         // }
        
       
  //       // if (stage === true) {
  //       //   navigate("/parking-page", {
  //       //     state: { data: location?.state?.data },
  //       //   });
  //       // }

  //       // console.log(result);
  //       // getApproval({
  //       //   applicationId,
  //       //   userId,
  //       // })
  //       //   .then((result) => {
  //       //     console.log(result);
  //       //     if (result.res.status === "Rejected") {
  //       // navigate("/congratulations");
  //       //       } else {
  //       //         navigate("/congratulations");
  //       //       }
  //       //     })
  //       //     .catch((err) => {
  //       //       console.log(err);
  //       //     });
  //       // })
  //     }
      

      
  

  console.log("this is work details")


  return (
    <div className={styles.body}> 
      {error && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1rem 1rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
            }}
            onClick={() => setError("")}
          >
            <h5
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
                fontSize:'16px'
              }}
            >
              <span style={{color:'#d32028'}}>Error:{' '}</span>{error}
            </h5>
          </div>
        </div>
      )}



      {bankUplodDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.uploadContainer}
          >
            <div className={styles.uploadBankContainer}>
              <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                Upload Bank Statement
              </p>

              <div
                {...getRootProps()}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  cursor: "pointer",
                  flex: 1,
                  background: "#fff3f3",
                  border: '1px solid #d32028',
                  borderRadius: "12px",
                }}
                //className ={styles.bgUploadContainer}
              >
                <input {...getInputProps()} />
                {selectedFile?.[0]?.name ? (
                  <>
                    {successMessage !== "" ? (
                      <>
                      {/*
                      <p
                        style={{
                          color: "#2c6ce3",
                          fontSize: "1.5em",
                        }}
                      >
                        Uploading...
                        
                      </p>
                      */}
                      
                      
                      <CircularProgress sx={{mt:1.3}}/>
                      <div style = {{margin:"1.5rem"}}>
                      {bankStatementAnalysis==="uploading"&&(<div><p>Please hold, Uploading Bank Statement ...</p></div>)}
                      {/* {bankStatementAnalysis==="assigningFundSource"&&(<div><p>Bank Statement Sucessfully uploaded</p><p>Please hold, Assigning Fund Source ...</p></div>)}
                      {bankStatementAnalysis==="evaluating"&&(<div><p>Bank Statement Sucessfully uploaded</p><p>Assigned Fund Source Sucessfully</p><p>Evaluating your application.....</p></div>)} */}
                      </div>

                      </>
                    ) : (
                      <div>{selectedFile?.[0]?.name}</div>
                    )}
                  </>
                ) : (
                  <>
                    <svg
                      width="50"
                      height="50"
                      viewBox="0 0 57 56"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      
                    >
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        fill="#F2F4F7"
                      />
                      <rect
                        x="3.5"
                        y="3"
                        width="50"
                        height="50"
                        rx="25"
                        stroke="#F9FAFB"
                        stroke-width="6"
                      />
                      <path
                        d="M32.1586 32.0806L27.6721 27.594M27.6721 27.594L23.1855 32.0806M27.6721 27.594V37.6887M37.0826 34.7613C38.1765 34.1649 39.0408 33.2211 39.5388 32.079C40.0369 30.9369 40.1404 29.6614 39.8331 28.454C39.5257 27.2465 38.825 26.1757 37.8416 25.4107C36.8581 24.6457 35.6479 24.2299 34.4019 24.2291H
                          32.9886C32.6491 22.916 32.0163 21.6968 31.1379 20.6634C30.2594 19.63 29.1581 18.8092 27.9167 18.2627C26.6753 17.7162 25.3262 17.4582 23.9708 17.5082C22.6154 17.5581 21.2889 17.9147 20.0912 18.551C18.8934 19.1874 17.8554 20.087 17.0554 21.1823C16.2553 22.2775 15.714 23.5399 15.472 24.8745C15.2301 26.2091 15.2938 27.5812 15.6585 28.8876C16.0231 30.194 16.6792 31.4007 17.5774 32.417"
                        stroke="#475467"
                        stroke-width="1.66667"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>

                    <p
                      style={{
                        color: "#2c6ce3",
                        fontSize: "1em",
                      }}
                    >
                      Click to upload
                    </p>
                    <p
                      style={{
                        fontSize: "1em",
                      }}
                    >
                      PDF of Bank Statement
                    </p>
                  </>
                )}
              </div>

              {selectedFile?.[0]?.name && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "1rem",
                  }}
                >
                  <InputText
                    placeholder="Password of PDF (if any)"
                    type="password"
                    value={password}
                    changeHandler={(e) => setPassword(e.target.value)}
                    square
                  />
                </div>
              )}


              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.5rem 2rem",
                  alignItems: "center",
                }}
              >
                <Button
                  text={"Close"}
                  onPress={() => setBankUploadDialog((s) => !s)}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  onPress={() => {
                    setSuccessMessage("uploading");
                    // setBankUploadDialog((s) => !s);
                    submitUpload();
                    // navigate("/parking-page", {
                    //   state: { data: location?.state?.data },
                    // });
                  }}
                  fullWidth={false}
                  disabled={!selectedFile?.[0]?.name}
                  insureFin={location?.state?.data?.channelId === 5}
                />
              </div>



            </div>
          </div>
        </div>
      )}



      {bankDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.bankSelectCard}>
            <div className={styles.bankContainer}>
              <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                Select Bank
              </p>
              <InputText
                placeholder="Search banks"
                square
                type="text"
                value={searchText}
                changeHandler={(e) => setSearchText(e.target.value)}
                style={{ width: "100%" }}
              />
              <div className={styles.bankList}>
                {BANK_LIST.filter((bank) =>
                  bank["Full name"]
                    .toLowerCase()
                    .includes(searchText.toLowerCase())
                )
                  .sort(
                    (a, b) =>
                      a["Full name"]
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase()) -
                      b["Full name"]
                        .toLowerCase()
                        .indexOf(searchText.toLowerCase())
                  )
                  .map((bank) => (
                    <div
                      className={styles.bank}
                      onClick={() => {
                        console.log();

                        setSelectedBank(bank["Name"]);
                      }}
                      style={{
                        backgroundColor:
                          selectedBank === bank["Name"] ? "#F5F5F5" : "",
                      }}
                      key={bank["Name"]}
                    >
                      <div
                        className={styles.bankImg}
                        style={{
                          backgroundImage: `url(${bank["Logo URL"]})`,
                        }}
                      ></div>
                      <p>{bank["Full name"]}</p>
                    </div>
                  ))}
              </div>
            <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  marginTop:'1rem',
                  alignItems: "center",
                
                }}
              >
                <Button
                  text={"Close"}
                  onPress={() => setBankDialog((s) => !s)}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  insureFin={location?.state?.data?.channelId === 5}
                  // onPress={() => setBankDialog((s) => !s)}
                  onPress={() => {
                    const bank = BANK_LIST.find(
                      (bank) => bank["Name"] === selectedBank
                    );

                    setBankDialog((s) => !s);
                    setAADialog((s) => !s);

                    // if (
                    //   bank &&
                    //   fipList.find(
                    //     (a) =>
                    //       a?.bank.toLocaleUpperCase() ===
                    //       bank["Name"].toLocaleUpperCase()
                    //   )?.health_up
                    // ) {
                    //   // navigate(`/account-aggregator`, { state: { bank } });
                    // } else {
                    //   // navigate(`/pdf-upload-bank`);
                    // }
                  }}
                  fullWidth={false}
                  disabled={!selectedBank}
                />
              </div>
              
            </div>
            
            
          </div>
        </div>
      )}



      {aaDialog && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.5)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className={styles.aaContainer}>
            <div className={styles.accountAggregatorbankContainer}>
              <p
                style={{
                  fontSize: "18px",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Account Aggregator
              </p>
              {/* <p style={{ fontSize: "1em" }}>
                You will be redirected to the bank's website for account
                aggregation
              </p> */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1rem",
                  // alignItems: "center",
                  // justifyContent: "flex-start",
                  flex: 1,
                  paddingTop: "1rem",
                  flexShrink: 0,
                  // alignItems: "center",
                }}
              >
                {BANK_LIST.find((bank) => bank["Name"] === selectedBank) &&
                fipList.find(
                  (a) =>
                    a?.bank.toLocaleUpperCase() ===
                    BANK_LIST.find(
                      (bank) => bank["Name"] === selectedBank
                    )?.Name?.toLocaleUpperCase()
                )?.health_up ? (
                  <div
                    onClick={() => {
                      setselectedMethod("AA");
                    }}

                    className={styles.textImageContainer}
                    style={{
                      border:
                        selectedMethod === "AA"
                          ? "1px solid #d32028"
                          : "1px solid var(--primary-border)",
                          // background: "#fff3f3",
                      
                      background:
                        selectedMethod === "AA"
                          ? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': '',
                    }}
                  >
                    <img

                    className={styles.image}
                      src={BankIcon}
                      alt=""
                    />
                    <p
                      

                      className={styles.text}
                    >
                      Securely Share Bank Statement by OTP sent through bank
                    </p>
                  </div>
                ) : null}
                <div
                  onClick={() => {
                    setselectedMethod("PDF");
                  }}

                  className={styles.textImageContainer}
                  style={{
                    border:
                      selectedMethod === "PDF"
                        ? "1px solid #d32028"
                        : "1px solid var(--primary-border)",
             
                    background:
                    selectedMethod === "PDF"
                      ? 'linear-gradient(95.71deg, rgba(255, 246, 246, 0.9) -0.64%, rgba(255, 247, 241, 0.9) 104.18%)': '',
                  }}
                >
                  <img
                    className={styles.image}
                    src={PdfIcon}
                    alt=""
                  />
                  <p
                    className={styles.text}
                  >
                    Upload Bank Statement
                  </p>
                </div>
              </div>

              <div className = {styles.buttonsContainer}
                // style={{
                //   display: "flex",
                //   justifyContent: "space-between",
                //   padding: "0.5rem 2rem",
                //   alignItems: "center",
                  
                // }}
              >
                <Button
                  text={"Back"}
                  onPress={() => {
                    setAADialog((s) => !s);
                    setBankDialog((s) => !s);
                  }}
                  fullWidth={false}
                  noRightImage
                  secondary
                />
                <Button
                  text={"Next"}
                  insureFin={location?.state?.data?.channelId === 5}
                  onPress={() => {
                    if (selectedMethod === "AA") {
                      getAARedirectLink();
                    } else {
                      setAADialog((s) => !s);
                      setBankUploadDialog((s) => !s);
                    }
                    // if (
                    //   BANK_LIST.find((bank) => bank["Name"] === selectedBank) &&
                    //   fipList.find(
                    //     (a) =>
                    //       a?.bank.toLocaleUpperCase() ===
                    //       BANK_LIST.find(
                    //         (bank) => bank["Name"] === selectedBank
                    //       )?.Name?.toLocaleUpperCase()
                    //   )?.health_up
                    // ) {
                    //   setAADialog((s) => !s);
                    // } else {
                    //   setAADialog((s) => !s);
                    //   setBankUploadDialog((s) => !s);
                    // }
                    // // navigate(`/account-aggregator`);
                  }}
                  fullWidth={false}
                />
              </div>
            </div>
          </div>
        </div>
      )}





      <div className={styles.backdrop}>
        
        <Navbar />
        
        <div className={styles.container}>
          
          {(
            <div className={styles.content}>
              <div
                 className={styles.title}
              >
                <p className={styles.headerText}>
                  Bank Statement
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // border: '1px solid #d32028',
                  padding: "8px 12px 12px 12px",
                  boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                  borderRadius: "0px 0px 12px 12px",
                  width: "100%",
                  //minHeight:"40vh",
                }}
              >
                <p className={styles.description}>
                  Uploading a bank account statement can enhance your chances of
                  availing better loan amount
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginTop:'10px'
                  }}
                >
                  <Button
                    insureFin={location?.state?.data?.channelId === 5}
                    // insureFin
                    text={"Select Bank"}
                    onPress={() => {
                      setBankDialog((s) => !s);
                      // submitEmploymentDetails();
                    }}
                    noRightImage
                    //disabled={!employmentDetailsSubmitted}
                    color ={"red"}
               
                  />
       
                </div>
              </div>
            </div>
          ) }
        </div>
        <Footer />
      </div>
    </div>
  );
}






export default BankStatementUpload

