import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import IconIncomeDetails from "../../../images/icons/income_details.svg";
import BankIcon from "../../../images/static_assests/bank.svg";
import PdfIcon from "../../../images/static_assests/pdf.svg";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import LoanStepCard from "../../../components/molecules/Card";
import InputText from "../../../components/atoms/InputText";
import Button from "../../../components/atoms/Button";
import Label from "../../../components/atoms/Label";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getApproval, updateEmployment } from "../../../services/application";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import Dropzone, { useDropzone } from "react-dropzone";
import { BANK_LIST } from "../../../utils/banks_list";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { bankStatementPdfUpload } from "../../../services/aa";
import { ApplicantStage } from "../../../utils/applicationStage";
import { Modal, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  assignInsurfinFundSource,
  evaluateInsurfinApplication,
} from "../../../services/riskEngine";

function WorkDetailsIndividualPage() {
  const [employmentDetailsSubmitted, setEmploymentDetailsSubmitted] = useState(false);
  const [newAuthToken, setNewAuthToken] = useState("");
  const location = useLocation();

  console.log(location?.state?.data, 'work details page');
  const [employmentType, setEmploymentType] = useState<"salaried" | "self-employed" >("salaried");

  const [state, setState] = useState({
    employerName: "",
    salary: "",
    incomePerMonth: "",
    typeOfBusiness: "",
  });

  const navigate = useNavigate();
  const [stage, setStage] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setLoading(false);
        setStage(res.data.skipStatement);
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate("/address", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.EmploymentDetails:
              setEmploymentDetailsSubmitted(true);
              navigate("/identify-yourself", {
                state: { data: location?.state?.data },
              });
              // navigate("/bank-statement-upload", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              // navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                console.log(err, 'work details error')
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    const getDetails = async () => {
      const response1 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          body: JSON.stringify({
            memberId: location?.state?.data.userId,
            // memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result1 = await response1.json();
      const authToken = result1.data;
      setNewAuthToken(authToken);
      localStorage.setItem('auth_token', authToken)
      if (location?.state?.data?.userId) {
        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/users/employment-details?userId=${location?.state?.data?.userId}&channelId=5`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result2 = await response2.json();

        console.log(result2);

        setEmploymentType(result2?.data?.employmentType || "salaried");

        setState({
          employerName:
            result2?.data?.employerName === "string"
              ? ""
              : result2?.data?.employerName,
          salary: result2?.data?.salary === 0 ? "" : result2?.data?.salary,
          incomePerMonth:
            result2?.data?.incomePerMonth === 0
              ? ""
              : result2?.data?.incomePerMonth,
          typeOfBusiness:
            result2?.data?.typeOfBusiness === "string"
              ? ""
              : result2?.data?.typeOfBusiness,
        });
      } else {
        setState({
          employerName: "",
          salary: "",
          incomePerMonth: "",
          typeOfBusiness: "",
        });
      }
    };
    getDetails();
  }, []);

  const submitEmploymentDetails = () => {
    setLoading(true);
    // setPanDetails(PANzoopData);
    updateEmployment(
      {
        userId: location?.state?.data?.userId,
        applicationId: location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application,
        employmentType: employmentType,
        employerName: state?.employerName,
        salary: Number(state?.salary),
        incomePerMonth: Number(state?.incomePerMonth),
        typeOfBusiness: state?.typeOfBusiness,
        channelId: 5,
      },
      newAuthToken
    )
      .then(async (result) => {
        setLoading(false);
        setEmploymentDetailsSubmitted(true);
        // setLoading(true)
        // setTimeout(()=>{
        //   setLoading(false)
        // },2000)
        navigate("/identify-yourself", {
          state: { data: location?.state?.data },
        });
        
        if (stage === true) {
          try {
            if (location?.state?.data?.channelId !== 3) {
              const responseFundSource = await assignInsurfinFundSource(
                newAuthToken,
                JSON.stringify({
                  applicationId: location?.state?.data?.applicationId,
                  fundCode: "GBP",
                  loanAmount: location?.state?.data?.fees,
                })
              );

              console.log(responseFundSource);
            }

            const responseEvaluate = await evaluateInsurfinApplication(
              newAuthToken,
              JSON.stringify({
                applicationId: location?.state?.data?.applicationId,
                userId: location?.state?.data?.userId,
              })
            );

            console.log(responseEvaluate);
            if (responseEvaluate.data === "green") {
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
            } else if (responseEvaluate.data === "red") {
              navigate("/parking-page-rejected", {
                state: { data: location?.state?.data },
              });
            } else if (responseEvaluate.data === "orange") {
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
            }
          } catch (error) {
            console.log(error);

            navigate("/parking-page", {
              state: { data: location?.state?.data },
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div className={styles.body}>   
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          {/* <LoanStepCard
            // description="Permanent Address & Current Location"
            title="Income details"
            image={IconIncomeDetails}
          /> */}
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100vh",
              }}
            >
              <CircularProgress />
            </Box>
          ) : (
            <div className={styles.content}>
              <div  className={styles.title}>
                <p className={styles.titleText}>
                  Employment Details
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // border: '1px solid #d32028',
                  paddingTop:'10px',
                  boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                  borderRadius: "0px 0px 12px 12px",
                  paddingBottom:'20px'
                 
                }}
              >
                <div className={styles.addressInputGrid}>
                
                    <div style={{display:'flex', justifyContent:'center', flexWrap:'wrap'}}>
                    <div
                      className={`${
                        employmentType === "salaried"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("salaried")}
                    >
                      <p
                        style={{
                          fontSize: "1em",
                          padding: "0.5rem",
                          margin: 0,
                        }}
                      >
                        Salaried
                      </p>
                    </div>
                    <div
                      className={`${
                        employmentType === "self-employed"
                          ? styles.employmentDetialsActive
                          : styles.employmentDetialsInactive
                      }`}
                      onClick={() => setEmploymentType("self-employed")}
                    >
                      <p
                        style={{
                          fontSize: "1em",
                          padding: "0.5rem",
                          margin: 0,
                        }}
                      >
                        Self Employed
                      </p>
                    </div>
                    </div>
                    
                  </div>

                 
                  {employmentType === "salaried" ? (
                    <>
                      <div className={styles.inputFieldCard}>
                        {/* <p style={{ fontSize: "1.2em" }}>Company Name</p> */}
                        <Label text="Company Name"/>
                        <InputText
                          value={state.employerName}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              employerName: e.target.value,
                            }))
                          }
                          square
                          placeholder="Ex: FeeMonk"
                        />
                      </div>
                      <div  className={styles.inputFieldCard}>
                        <Label text="Net Monthly Salary" />
                        <InputText 
                          type='number'
                          value={state.salary}
                          changeHandler={(e) =>
                            setState((s) => ({ ...s, salary: e.target.value }))
                          }
                          square
                          placeholder="₹"
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div  className={styles.inputFieldCard}>
                        <Label text="Profession" />
                        <InputText
                          value={state.typeOfBusiness}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              typeOfBusiness: e.target.value,
                            }))
                          }
                          square
                          placeholder="Profession"
                        />
                      </div>
                      <div  className={styles.inputFieldCard}>
                        <Label text="Monthly income" />
                        <InputText 
                          type='number'
                          value={state.incomePerMonth}
                          changeHandler={(e) =>
                            setState((s) => ({
                              ...s,
                              incomePerMonth: e.target.value,
                            }))
                          }
                          square
                          placeholder="₹"
                        />
                      </div>
                    </>
                  )}          
                </div>
              </div>
          )}
          <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop:"20px"
                    }}
                  >
                    <Button
                      insureFin={location?.state?.data?.channelId === 5}
                      onPress={() => {
                        submitEmploymentDetails();
                      }}
                      text={"Save"}
                      noRightImage
                      disabled={
                        (employmentType === "salaried" &&(!state.employerName || !state.salary)) || (employmentType==='self-employed'
                          && (!state.incomePerMonth || !state.typeOfBusiness)) ||
                        employmentDetailsSubmitted
                      }
                    />
                    
                  </div>
                  
        </div>
        <Footer />
      
        
      </div>
    </div>
  );
}

export default WorkDetailsIndividualPage;
