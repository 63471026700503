import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import KYC from "../../../images/icons/kyc.svg";

import { RadioGroup, FormControlLabel, Radio } from "@mui/material";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import LoanStepCard from "../../../components/molecules/Card";

import InputText from "../../../components/atoms/InputText";


import Button from "../../../components/atoms/Button";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import { updateAddress } from "../../../services/application";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { ApplicantStage } from "../../../utils/applicationStage";

function AddressIndividualPage() {
  // const { panProData, userId } = useContext(DataContext) as DataContextType;
  const navigate = useNavigate();
  const [newAuthToken, setNewAuthToken] = useState("");

  const location = useLocation();
  const panProData = location.state?.data
  const [state, setState] = useState({
    currentAddress: panProData?.currentAddress || "",
    currentCity: panProData?.currentCity || "",
    currentState: panProData?.currentState || "",
    currentPincode: panProData?.currentPincode || "",
    permanentAddress:panProData?.currentAddress || "",
    permanentCity:panProData?.currentCity || "",
    permanentState: panProData?.currentState || "",
    permanentPincode: panProData?.currentPincode || "",
    userId: location?.state?.data?.userId || "",
    applicationId:  panProData?.existing ?  panProData?.applicationId : location?.state?.data?.application || "",
  });
  const [value, setValue] = useState('')
  console.log(panProData , 'address page')
  useEffect(() => {
    const getDetails = async () => {
      const response1 = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
        method: "POST",
        body: JSON.stringify({
          memberId: location?.state?.data?.userId,
          // memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const result1 = await response1.json();
      const authToken = result1.data;

      setNewAuthToken(authToken);

      if (location?.state?.data?.userId) {
        const response2 = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/users/profile-details?userId=${location?.state?.data?.userId}&channelId=${location?.state?.data?.channelId}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${authToken}`,
            },
          }
        );

        const result2 = await response2.json();

        console.log(result2);

        setState({
          currentAddress: result2?.data?.currentAddress || "",
          currentCity: result2?.data?.currentCity || "",
          currentState: result2?.data?.currentState || "",
          currentPincode: result2?.data?.currentPincode || "",
          permanentAddress: result2?.data?.currentAddress || "",
          permanentCity: result2?.data?.currentCity || "",
          permanentState: result2?.data?.currentState || "",
          permanentPincode: result2?.data?.currentPincode || "",
          userId: panProData.userId || "",
          applicationId: panProData.existing ?  panProData.applicationId : location?.state?.data?.application || "",
        });
      } else {
        setState({
          currentAddress: "",
          currentCity: "",
          currentState: "",
          currentPincode: "",
          permanentAddress: "",
          permanentCity: "",
          permanentState: "",
          permanentPincode: "",
          userId: panProData.userId || "",
          applicationId: panProData.existing ?  panProData.applicationId : location?.state?.data?.application || "",
        });
      }
    };

    getDetails();
  }, []);

  const [stage,setStage] = useState(null)

  useEffect(() => {
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        setStage(res.data.skipStatement)
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/identify-yourself",{
                state: { data: location?.state?.data ,stage: res.data.skipStatement},
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data,stage: res.data.skipStatement },
              });
              // navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/pan-details/:data`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  const submitAddress = () => {
    // setPanDetails(PANzoopData);
    updateAddress(
      {
        userId: state.userId,
        applicationId: state.applicationId,
        currentAddress: state.currentAddress,
        currentCity: state.currentCity,
        currentState: state.currentState,
        currentPincode: state.currentPincode,
        permanentAddress:
        state.permanentAddress
            ? state.permanentAddress
            : state.currentAddress,
        permanentCity:
        state.permanentCity ? state.permanentCity : state.currentCity,
        permanentState:
        state.permanentState ? state.permanentState : state.currentState,
        permanentPincode:
        state.permanentPincode
            ? state.permanentPincode
            : state.currentPincode,
        channelId: 5,
      },
      newAuthToken
    )
      .then((result) => {
        console.log(result);
        navigate("/work-details", { state: { data: location?.state?.data ,stage} });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleRadioBtnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if((event.target as HTMLInputElement).value === 'no'){
      setState({...state, 
        permanentAddress: state.permanentAddress || '',
        permanentCity : state.permanentCity || '',
        permanentState: state.permanentState || '' ,
        permanentPincode: state.permanentPincode || '',
        currentAddress: '',
        currentCity: '',
        currentState: '',
        currentPincode: '',
      })
    }
    else{
      setState({
        ...state,
        permanentAddress: state.permanentAddress || '',
        permanentCity : state.permanentCity || '',
        permanentState: state.permanentState || '' ,
        permanentPincode: state.permanentPincode || '',
        currentAddress: state.permanentAddress || '',
        currentCity: state.permanentCity || '',
        currentState: state.permanentState || '',
        currentPincode: state.permanentPincode,
      })
    }
    setValue((event.target as HTMLInputElement).value);
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div className={styles.card1}>
            <div className={styles.title}  >
              <p className={styles.titleText}>
                Permanent Address
              </p>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "1rem",
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "0px 0px 12px 12px",
              }}
            >
              <p>
                <span
                  style={{
                    color: "#D32028",
                    fontSize: "1.2em",
                    lineHeight:1
                  }}
                >
                  Note: 
                </span>
                  The following address details are obtained from your e-kyc,
                  please feel free to edit in case of changes
                </p>
              <div className={styles.addressInputGrid}>
                <InputText
                  // gridArea="door"
                  square
                  placeholder="Door No."
                  value={state.permanentAddress}
                  changeHandler={(e) =>
                    setState((s) => ({ ...s, permanentAddress: e.target.value }))
                  }
            
                />
                  <InputText
                    gridArea="city"
                    square
                    placeholder="City"
                    value={state.permanentCity}
                    changeHandler={(e) =>
                      setState((s) => ({ ...s, permanentCity: e.target.value }))
                    }
                 
                  />
                  <InputText
                    gridArea="state"
                    square
                    placeholder="State"
                    value={state.permanentState}
                    changeHandler={(e) =>
                      setState((s) => ({ ...s, permanentState: e.target.value }))
                    }
                  />
                  <InputText
                    gridArea="pin" 
                    type='number'
                    square
                    placeholder="Pincode"
                    value={state.permanentPincode}
                    changeHandler={(e) =>
                      setState((s) => ({
                        ...s,
                        permanentPincode: e.target.value,
                      }))
                    }
                  />
              </div>
              <div className={styles.formContainer}>
                <p>Is this your Current Address?</p>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group" 
                  sx={{display:'flex', flexDirection:'row', mt:-3, }}
                  onChange={handleRadioBtnChange}
                >
                  <FormControlLabel sx={{width:'10%', 
                    '& .MuiFormControlLabel-label': {
                    fontFamily: 'Outfit',
                  },
                  }} value="yes" control={<Radio color="error" />} label="Yes" />
                  <FormControlLabel  sx={{width:'10%', ml:3,
                    '& .MuiFormControlLabel-label': {
                      fontFamily: 'Outfit',
                    },
                  }} value="no" control={<Radio color="error" />} label="No" />
                </RadioGroup>
              </div>
            </div>
            </div>
            <div className={styles.card1}>
            <div style={{
                display: "flex",
                flexDirection: "column",
                // border: '1px solid #d32028',
                boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                borderRadius: "12px",
              }}
            >
            <div className={styles.title}>
                    <p className={styles.titleText}>
                      Current Address
                    </p>
                </div>
              <div className={styles.addressInputGrid2}>
                <InputText
                  // gridArea="door"
                  square
                  placeholder="Door No."
                  value={state.currentAddress}
                  changeHandler={(e) => {
                    setState((s) => ({
                      ...s,
                      currentAddress: e.target.value,
                    }));
                  }}
                />
                  <InputText
                    gridArea="city"
                    square
                    placeholder="City"
                    value={state.currentCity}
                    changeHandler={(e) => {
                      setState((s) => ({
                        ...s,
                        currentCity: e.target.value,
                      }));
                    }}
                  />
                  <InputText
                    gridArea="state"
                    square
                    placeholder="State"
                    value={state.currentState}
                    changeHandler={(e) => {
                      setState((s) => ({
                        ...s,
                        currentState: e.target.value,
                      }));
                    }}
                  />
                  <InputText
                    gridArea="pin" 
                    type='number'
                    square
                    placeholder="Pincode"
                    value={state.currentPincode}
                    changeHandler={(e) => {
                      setState((s) => ({
                        ...s,
                        currentPincode: e.target.value,
                      }));
                    }}
                  />
              </div>
            </div>
            </div> 
          </div>
          <Button
            text={"Save & Next"}
            onPress={() => {
              submitAddress();
            }}
            insureFin={location?.state?.data?.channelId === 5}
            disabled={
              !state.currentAddress ||
              !state.currentCity ||
              !state.currentState ||
              !state.currentPincode ||
              (value === "yes" &&
                (!state.permanentAddress ||
                  !state.permanentCity ||
                  !state.permanentState ||
                  !state.permanentPincode))
            }
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default AddressIndividualPage;
