import React, { useEffect, useState } from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useNavigate, useLocation } from "react-router-dom";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { ApplicantStage } from "../../utils/applicationStage";
import { fraudCheckInsurfin } from "../../services/riskEngine";

function PFCollection() {
  const navigate = useNavigate();
  const location = useLocation();

  const [product, setProduct] = useState<{
    id?: number;
    applicationId?: string;
    fundCode?: string;
    productId?: string;
    loanAmount?: number;
    emi?: number;
    emiFirstDate?: string;
    dayOfEmi?: number;
    sanctionLetterUrl?: string;
    productDetails?: any;
  }>({});

  useEffect(() => {
    getProductsDetails();
  }, []);

  const [status, setStatus] = useState(null);

  useEffect(() => {
    const applicationId = location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    const intervalId = setInterval(() => {
      fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.data?.stage) {
            setStatus(res?.data?.stage);
          }
        })
        .catch((err) => console.log(err));
    }, 2000);
    return () => clearInterval(intervalId);
  }, [location?.state?.data?.application]);

  useEffect(() => {
    if (status === ApplicantStage.Disbursed) {
      navigate("/disbursed-page", {
        state: { data: location?.state?.data },
      });
    }
  }, [status, location?.state?.data, navigate]);

  useEffect(() => {
    async function fetchData() {
      const response1 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          body: JSON.stringify({
            memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result1 = await response1.json();
      const authToken = result1.data;

      // const fraudCheckResult = await fraudCheckInsurfin(
      //   authToken,
      //   JSON.stringify({
      //     applicationId: location?.state?.data?.application,
      //     userId: location?.state?.data?.userId,
      //   })
      // );

      // console.log(fraudCheckResult);
    }
    fetchData();
  }, [location?.state?.data?.application, location?.state?.data?.userId]);

  const getProductsDetails = async () => {
    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_DASHBOARD_URL
        }/admin/application/sanction-details?applicationId=${
          location?.state?.data?.application || "FM24000923"
        }`
      );
      const result = await response.json();
      console.log(result);
      setProduct(result.data);

      const response1 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/login/auth`,
        {
          method: "POST",
          body: JSON.stringify({
            // memberId: location?.state?.data.userId,
            memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          }),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result1 = await response1.json();
      const authToken = result1.data;

      const response2 = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/products/id?productId=${result.data.productId}`,
        {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        }
      );

      const result2 = await response2.json();
      console.log(result2);

      setProduct((prev) => ({
        ...prev,
        productDetails: result2.data,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const paymentLink = () => {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
      method: "POST",
      body: JSON.stringify({
        // memberId: location?.state?.data.userId,
        memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((data) => {
        console.log(data);
        if (data.message === "Successful") {
          fetch(
            `${process.env.REACT_APP_DASHBOARD_URL}/pre-disbursement-collection/create-link-for-user`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${data.data}`,
              },
              body: JSON.stringify({
                userId:
                  location?.state?.data?.userId ||
                  "a34c5d28-f8bf-432a-9d65-fe941e68c433",
                applicationId:
                  location?.state?.data?.application || "FM24000923",
                advEmi:
                  Number(product?.productDetails?.advanceEmis) *
                  Number(product?.emi),
                processingFee:
                  Number(product?.productDetails?.processingfeeValue) *
                    Number(product?.loanAmount) +
                  0.18 *
                    Number(product?.loanAmount) *
                    Number(product?.productDetails?.processingfeeValue),
                totalAmount: Math.ceil(
                  Number(product?.productDetails?.processingfeeValue) *
                    Number(product?.loanAmount) +
                    0.18 *
                      Number(product?.loanAmount) *
                      Number(product?.productDetails?.processingfeeValue) +
                    Number(product?.productDetails?.advanceEmis) *
                      Number(product?.emi)
                ),
                instituteName: '1a62ed6b-a5f5-4ab3-acfa-6e353b070149',
                status: 0,
                utr: "string",
                mode: 0,
                paidDate: {},
                remarks: "string",
              }),
            }
          )
            .then((res) => res.json())
            .then((data) => {
              console.log(data);
              window.open(data.data, "_blank", "noopener,noreferrer");
            });
        }
      });
    });
  };

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <br />
          <p
            style={{
              fontSize: "18px",
              textAlign: "center",
            }}
          >
            Your Mandate process is complete, disbursement will be done after
            the payment of Rs.{" "}
            <strong
              style={{
                fontFamily: "Outfit",
              }}
            >
              {Math.ceil(
                Number(product?.productDetails?.processingfeeValue) *
                  Number(product?.loanAmount) +
                  0.18 *
                    Number(product?.loanAmount) *
                    Number(product?.productDetails?.processingfeeValue) +
                  Number(product?.productDetails?.advanceEmis) *
                    Number(product?.emi)
              )}{" "}
            </strong>
            as Processing Fee / Advance EMI.
          </p>
          <div style={{ display: "flex" }}>
            <div>
              <p>Processing Fee</p>
              <p>GST On PF(18%)</p>
              <p>No. of Advance EMIs</p>
              <p>Advance EMI Amount</p>
            </div>
            <div style={{ marginLeft: "1.5em" }}>
              <p>:</p>
              <p>:</p>
              <p>:</p>
              <p>:</p>
            </div>
            <div style={{ marginLeft: "1.5em" }}>
              <p>
                Rs.{" "}
                {Number(product?.productDetails?.processingfeeValue) *
                  Number(product?.loanAmount)}
              </p>
              <p>
                Rs.{" "}
                {Math.ceil(
                  0.18 *
                    Number(product?.loanAmount) *
                    Number(product?.productDetails?.processingfeeValue)
                )}
              </p>
              <p>{Number(product?.productDetails?.advanceEmis)}</p>
              <p>
                Rs.{" "}
                {Number(product?.productDetails?.advanceEmis) *
                  Number(product?.emi)}
              </p>
            </div>
          </div>
                <br/>
          {/* <p >Processing Fee : Rs.{" "} {Number(product?.productDetails?.processingfeeValue)*Number(product?.loanAmount)}</p>
          <p>Processing Fee GST(18%) : Rs.{" "} {(
                0.18 * Number(product?.loanAmount)*Number(product?.productDetails?.processingfeeValue))}</p>
          <p>No. of Advance EMIs : {Number(product?.productDetails?.advanceEmis)}</p>
          <p>Advance EMI Amount : Rs.{" "} {( Number(product?.productDetails?.advanceEmis) *
                Number(product?.emi))}</p> */}
          <Button
            insureFin={location?.state?.data?.channelId === 5}
            text={"Pay Now"}
            onPress={() => paymentLink()}
          />
          <br/>
          <button onClick={()=> navigate('/disbursed-page', {state:{data:location?.state?.data}})}> Skip (for dev purpose) </button>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default PFCollection;
