import  { useContext, useEffect, useState } from "react";
import Button from "../../../components/atoms/Button";
import styles from "./index.module.css";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../../components/atoms/InputText";
import { Box, Typography } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "../../../components/atoms/Label";
import ArrowLeft from "../../../images/icons/arrow_left.svg";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import FeemonkText from "../../../components/atoms/FeemonkText";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import { createApplication } from "../../../services/application";
import axios from "axios";
import moment from "moment";
import { ApplicantStage } from "../../../utils/applicationStage";
import { encode } from "punycode";
import axiosInstance from "../../../../helpers/axios";

interface Institute {
  brandName: string
}

function StudentDetails() {
  const navigate = useNavigate()
  const location = useLocation()
  const stateData = location?.state?.data
  const [studentName, setStudentName] = useState('')
  const [instituteName, setInstituteName] = useState(stateData?.instituteName)
  const [courseName, setCourseName] = useState(stateData?.course)
  const [courseFees, setCourseFees] = useState(stateData?.courseFees)
  const [instituteList, setInstituteList] = useState<Institute[]>([])
  console.log(stateData, 'student details page')
  const getStudentDetails = async () =>{
    const applicationId = stateData?.existing? stateData?.applicationId: stateData?.application
    const response = await axios(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${stateData?.userId}&applicationId=${applicationId}`)
    if(response?.status===200){
      const {studentName, courseFees, instituteName, courseName} = response?.data?.data
      setStudentName(studentName)
      setCourseName(courseName)
      setInstituteName(instituteName)
      setCourseFees(courseFees)
    }
    console.log(response, 'response')
  }
  useEffect(() => {
    if (stateData?.studentName || stateData?.courseFees || stateData?.instituteName || stateData?.course) {
      setStudentName(stateData.studentName || '');
      setCourseName(stateData?.courseName ? stateData.courseName : stateData?.course ? stateData.course : '');
      setInstituteName(stateData.instituteName || '');
      setCourseFees(stateData.courseFees || '');
    } else {
      getStudentDetails();
    }

  }, [])

  
  const getInstituteInfo= async (searchVal: any)=>{
    setInstituteName(searchVal)
      try {
        if(searchVal?.length>=4){
            const response = await axiosInstance(`${process.env.REACT_APP_DASHBOARD_URL}/institute/info/name?name=${searchVal}`)
            const list = response?.data?.data
            setInstituteList(list)
          }
      } catch (error) {
        setInstituteList([])
        console.log(error, 'institute api err')
      }
 
  }
   

  const handleInstituteSelect = (name: string)=> {
    setInstituteName(name)
    setInstituteList([])
  }

  const handleContinue = async () => {
     const data = {
      applicationId: stateData?.existing ?  stateData?.applicationId : stateData?.application,
      courseFees:  courseFees,
      instituteName: instituteName,
      courseName: courseName,
      isCoapplicant: true,
      loanAmount: courseFees,
      studentName: studentName,
      channel:"2"
    }
    const authToken = localStorage.getItem("auth_token") || "";
   
    try {
      const response = await fetch(`${process.env.REACT_APP_DASHBOARD_URL}/admin/application/update`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${authToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
  
      if (!response.ok) {
        console.log(response)
      }
  
      const result = await response.json();
      const passData = {...location?.state?.data, fees: courseFees, courseFees}
      navigate("/bank-statement-upload", {
          state: { data:  passData },
      });
      return true
    } catch (error) {
      console.log(error, 'error')
      return false;
    }

 }



 {console.log(instituteList, 'list', studentName, instituteName, courseName,courseFees)}
  return (
    <div className={styles.body}>
   
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
             <div className={styles.title}>
                <h1 className={styles.headerText}>Student Details</h1>
             </div>
              <div>
              <Label text="Student Name" left />
              <InputText
                square
                placeholder="Please Enter Student Name"
                changeHandler={(e) => setStudentName(e.target.value)}
                value={studentName}
                style={{marginBottom:'12px'}}
              />
              <Label text="School/ Institute Name" left />       
              <InputText
                square
                placeholder="School/ Institute Name"
                changeHandler={(e) =>{
                  getInstituteInfo(e.target.value)
                  // debouncedGetInstituteInfo(e.target.value); 

                } }
                value={instituteName}
                style={{marginBottom:'12px'}}
                disabled={stateData?.flow==='apply-now'? false: !!instituteName}
              />
              {instituteList.length!==0 &&
                    <div className={styles.listContainer}>
                  
                    {instituteList?.map((item)=>{
                        return(
                          <div onClick={() => handleInstituteSelect(item?.brandName)} className="instituteName" style={{cursor:'pointer'}}>
                          <p  style={{fontWeight:'600',paddingTop:'0.5em',paddingLeft:'0.5em'}}>{item?.brandName}</p>
                          </div>
                        )
                        }
                      )}
                    </div> 
              }
              
              <Label text="Class/ Course Name" left />
              <InputText
                square
                placeholder="Class/ Course Name"
                changeHandler={(e) => setCourseName(e.target.value)}
                value={courseName}
                disabled={stateData?.flow==='apply-now'? false: !!courseName}
                style={{marginBottom:'12px'}}
              />
              <Label text="Total Annual Fee" left />
              <InputText
                square
                placeholder="₹"
                changeHandler={(e) => setCourseFees(e.target.value)}
                value={courseFees}
                disabled={stateData?.flow==='apply-now'? false: !!courseFees}
                type='number'
                style={{marginBottom:'12px'}}
              />
              </div>
             
          <div style={{alignSelf:'center', marginTop:'10px'}}>
            <Button
              onPress={() => handleContinue()}
              imageRight={ArrowLeft}
              text={"Continue"}
              disabled={!studentName || !instituteName || !courseName || !courseFees}
            />
          </div>
          
        </div>
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default StudentDetails;
