import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import IconIncomeDetails from "../../images/icons/income_details.svg";
import BankIcon from "../../images/static_assests/bank.svg";
import PdfIcon from "../../images/static_assests/pdf.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import LoanStepCard from "../../components/molecules/Card";
import InputText from "../../components/atoms/InputText";
import Button from "../../components/atoms/Button";
import Label from "../../components/atoms/Label";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import { getApproval, updateEmployment } from "../../services/application";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import Dropzone, { useDropzone } from "react-dropzone";
import { BANK_LIST } from "../../utils/banks_list";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { bankStatementPdfUpload } from "../../services/aa";
import { ApplicantStage } from "../../utils/applicationStage";
import { Modal, Box, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {
  assignInsurfinFundSource,
  evaluateInsurfinApplication,
} from "../../services/riskEngine";

import feemonkLoader from "../../images/feemonk-loading-image.png"




const BankStatementEvaluation = () => {
  const [newAuthToken, setNewAuthToken] = useState("");
  const [error, setError] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [open, setOpen] = useState(false);

  const [bankStatementAnalysis, setBankStatementAnalysis] = useState("");

  const bankstatementState = ["assigningFundSource", "evaluating"];

  const location = useLocation();
  console.log("decode", location?.state?.data);

  console.log("this is location from bankstatement", location);

  const { userId, applicationId } = useContext(DataContext) as DataContextType;

  const navigate = useNavigate();

  const [bankDialog, setBankDialog] = useState(false);
  const [aaDialog, setAADialog] = useState(false);
  const [selectedMethod, setselectedMethod] = useState("");
  const [bankUplodDialog, setBankUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState<File[] | null>(null);
  const [stage, setStage] = useState(null);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
  });

  //get auth token


  useEffect(() => {
    setUserData(location?.state?.data);
      fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
        method: "POST",
        body: JSON.stringify({
          // memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
          memberId: location?.state?.data?.userId,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      })
        .then((response) => response.json())
        .then(async (result1) => {
          console.log("here");
          const authToken = result1.data;
  
          console.log("this is auth token from pan", authToken);
          setNewAuthToken(authToken);
          try {
            if (userData.channelId !== 3) {
              console.log("here1");

              setBankStatementAnalysis("assigningFundSource")
              // const responseFundSource = await assignInsurfinFundSource(
              //   result1.data,
              //   JSON.stringify({
              //     //applicationId: location?.state?.data?.applicationId,
              //     applicationId: location?.state?.data?.application,
              //     fundCode: "GBP",
              //     //loanAmount: location?.state?.data?.fees,
              //     loanAmount: location?.state?.data?.fees,
              //   })
              // );
      
              // console.log(responseFundSource);
            }
      
            setTimeout(async () => {
              setLoading(true);
              setBankStatementAnalysis("evaluating")
              const responseEvaluate = await evaluateInsurfinApplication(
                result1.data,
                JSON.stringify({
                  applicationId: location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application,
                  userId: location?.state?.data?.userId,
                })
              );
      
              console.log("this is evalute response", responseEvaluate?.data);
              setLoading(false);
              setSuccessMessage("");
      
              if (responseEvaluate.data) {
                navigate("/select-emi", {
                  state: { data: location?.state?.data },
                });
              }
              //  else if (responseEvaluate.data === "red") {
              //   navigate("/parking-page-rejected", {
              //     state: { data: location?.state?.data },
              //   });
              // } else if (responseEvaluate.data === "orange") {
              //   navigate("/parking-page", {
              //     state: { data: location?.state?.data },
              //   });
              // }
            }, 5000);
          } catch (error) {
            console.log("here3");
            console.log(error);
      
            navigate("/parking-page", {
              state: { data: location?.state?.data },
            });
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
  
   
    
  }, [location?.state?.data]);

  return (
    <div className={styles.body}>
      {error && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "2rem 5rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
            }}
            onClick={() => setError("")}
          >
            <h1
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
              }}
            >
              Error: {error}
            </h1>
          </div>
        </div>
      )}

      <div className={styles.backdrop}>
        <Navbar />

        <div style ={{display:"flex", justifyContent:"center", alignItems:"center"}} //className={styles.container}
        >
          {
            <div //className={styles.content}
            >
              {/* <div
                style={{
                  padding: "1rem",
                  background: "var(--box-background-dark)",
                  border: "1px solid var(--primary-border-dark)",
                  borderRadius: "12px 12px 0px 0px",
                  width: "100%",
                }}
              >
                {bankStatementAnalysis === bankstatementState[0] && (
                  <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    Assinging Fund Source
                  </p>
                )}
                {bankStatementAnalysis === bankstatementState[1] && (
                  <p style={{ fontSize: "1.2em", fontWeight: "bold" }}>
                    Evaluating Application
                  </p>
                )}
              </div> */}
              <div
                // style={{
                //   display: "flex",
                //   flexDirection: "column",
                //   // justifyContent: "center",
                //   // alignItems: "center",
                //   gap: "1rem",
                //   border: "1px solid var(--primary-border-dark)",
                //   background: "var(--box-background)",
                //   padding: "1rem",
                //   boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                //   borderRadius: "0px 0px 12px 12px",
                //   width: "100%",
                //   //minHeight:"40vh",
                // }}
              >
                <p style={{ fontSize: "1.2em", textAlign: "justify",marginTop:"4rem"}}>
                  Your application is under Evaluation, Please do not close or Refresh the Tab.
                </p>
                {/* <p style={{ fontSize: "1.2em", textAlign: "justify" }}>
                  Uploading a bank account statement can enhance your chances of
                  availing better loan amount
                </p> */}
                <section
                  style={{
                    display: "flex",
                    flexDirection:"column",
                    justifyContent: "center",
                    alignItems: "center",
                    marginBottom:"4rem" 
                  }}
                >
                  {/* <CircularProgress /> */}
                  
                  <div >
                    <img  src= {feemonkLoader} alt="Loading..." />
                  </div>

                  <div style={{ margin: "1.5rem" }}>
                    
                    {bankStatementAnalysis === "assigningFundSource" && (
                      <div>
                        
                        {/* <p>Please hold, Assigning Fund Source ...</p> */}
                      </div>
                    )}
                    {bankStatementAnalysis === "evaluating" && (
                      <div>
                        
                        {/* <p>Assigned Fund Source Sucessfully</p> */}
                        <p style={{fontSize:'18px', fontWeight:'bold'}}>Evaluating your application, Please wait.....</p>
                      </div>
                    )}
                  </div>
                </section>
              </div>
            </div>
          }
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default BankStatementEvaluation;
