import  { useContext, useEffect, useState } from "react";
import Button from "../../../components/atoms/Button";
import styles from "./index.module.css";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import InputText from "../../../components/atoms/InputText";
import { Box, Typography, } from "@mui/material";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Label from "../../../components/atoms/Label";
import ArrowLeft from "../../../images/icons/arrow_left.svg";
import Navbar from "../../../components/molecules/Navbar";
import Footer from "../../../components/molecules/Footer";
import FeemonkText from "../../../components/atoms/FeemonkText";
import { DataContext } from "../../../context/DataContext";
import { DataContextType } from "../../../types/DataContextType";
import { createApplication } from "../../../services/application";
import axios from "axios";
import moment from "moment";
import { ApplicantStage } from "../../../utils/applicationStage";
import { encode } from "punycode";

function EnterPanIndividualPage() {
  const location = useLocation()
  const pathSegments = window.location.pathname.split('/');
  const encodedData = pathSegments[pathSegments.length - 1];
  const data = encodedData
  const navigate = useNavigate();
  // const { data } = useParams();
  const [panData, setPanData] = useState({})
  let [userData, setUserData] = useState<{
    application:string,
    dob:string,
    applicationId: string;
    userId: string;
    email: string;
    panId: string;
    dateOfBirth: string;
    instituteId: string;
    firstName: string;
    lastName: string;
    mobile: string;
    course: string;
    fees: number;
    studentName: string;
    instituteName: string;
    redirectUrl: string;
    channelId: number;
    flow:string;
  }>({
    application:'',
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    dob:'',
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
    flow:''
  });

  const [pan, setPan] = useState(
    process.env.NODE_ENV === "development" ? "" : userData.panId
  );
  const [dob, setDob] = useState(
    process.env.NODE_ENV === "development" ? "" : userData.dateOfBirth
  );

  const { updatePanProData, saveIds } = useContext(
    DataContext
  ) as DataContextType;

  const [position, setPosition] = useState<{
    latitude: number | null;
    longitude: number | null;
  }>({ latitude: null, longitude: null });

  const [error, setError] = useState("");

  useEffect(() => {
    if (data) {
      try {
        sessionStorage.setItem("data",data)
        const jsonData = atob(data)
        setUserData(JSON.parse(jsonData));
      } catch (error) {}
    }
  }, []);

  useEffect(() => {
    let jsonData:any;

    if (data) {
      try {
        jsonData = atob(data);
      } catch (error) {
        console.error("Error decoding data:", error);
        jsonData = '';
      }
    }
    
    let checkoutData:any;
    try {
      checkoutData = JSON.parse(jsonData || '{}');
    } catch (error) {
      console.error("Error parsing JSON:", error);
    }
   
    fetch(
        `${process.env.REACT_APP_BASE_URL}/applications/getApplications/${checkoutData?.mobile}`
      )
      .then((res) => res.json())
      .then((response) =>{
        const prevApplication = response?.res?.pop()?.obj?.data
        if(prevApplication){
          userData = {...prevApplication, existing:true, flow:checkoutData?.flow}
        }
        const applicationId = prevApplication?.applicationId
        console.log(prevApplication, 'prevApplication')

        fetch(
            `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
          )
          .then((res) => res.json())
          .then((res) => {
            if (res?.data?.stage) {
              console.log(res?.data, 'stage data')
              switch (res?.data?.stage) {
                case ApplicantStage.PanVerification:
                  navigate("/address-details", {
                    state: { data: userData },
                  });
                  break;
                case ApplicantStage.EmploymentDetails:
                  navigate("/work-details", { state: { data: userData } });
                  break;
                case ApplicantStage.AddressDetails:
                  navigate("/address-details", { state: { data: userData } });
                  break;
                case ApplicantStage.BankStatement:
                  navigate("/view-offers", { state: { data: userData } });
                  break;
                case ApplicantStage.AwaitingApproval:
                  navigate("/parking-page", { state: { data: userData } });
                  break;
                case ApplicantStage.CoapplicantRequired:
                  navigate("/parking-page", { state: { data: userData } });
                  break;
                case ApplicantStage.AwaitingCoapplicantConsent:
                  navigate("/parking-page", { state: { data: userData } });
                  break;
                case ApplicantStage.Approved:
                  navigate("/select-emi", { state: { data: userData } });
                  break;
                case ApplicantStage.SanctionTermsAccepted:
                  navigate("/sanctions", { state: { data: userData } });
                  break;
                case ApplicantStage.Digilocker:
                  navigate("/sanctions", { state: { data: userData } });
                  break;
                case ApplicantStage.Selfie:
                  navigate("/sanctions", { state: { data: userData } });
                  break;
                case ApplicantStage.Agreement:
                  navigate("/sanctions", { state: { data: userData } });
                  break;
                case ApplicantStage.BankAccountVerified:
                  navigate("/sanctions", { state: { data: userData } });
                  break;
                case ApplicantStage.Mandate:
                  navigate("/disbursed-page", { state: { data: userData } });
                  break;
                case ApplicantStage.PFCollections:
                  navigate("/pf-collection", { state: { data: userData } });
                  break;
                case ApplicantStage.ProcessComplete:
                  navigate("/disbursed-page", { state: { data: userData } });
                  break;
                case ApplicantStage.Disbursed:
                  navigate("/disbursed-page", { state: { data: userData } });
                  break;
                case ApplicantStage.Rejected:
                  navigate("/rejected-page", { state: { data: userData } });
                  break;
                default:
                  navigate(`/pan-details/:data`);
                  break;
              }
            }
          })
      })
      .catch((err) => console.log(err));
  }, [userData.applicationId]);

  const [openedModal, setOpened] = useState<boolean>(false); // Define open state variable

  const [checked, setChecked] = useState(false);



  const handleCheck = (event: any) => {
    setChecked(event);
  };

  const handleCloseConsent = () => setOpened(false);

  const [link, setLink] = useState("");

  const handleStartSession = (value: any) => {
    console.log("exe");
    const randomGen =
      Date.now().toString(36) + Math.random().toString(36).substr(2);

    (window as any)
      .getBureauSession(
        process.env.REACT_APP_CONSENT_KEY,
        randomGen,
        value.firstName,
        "",
        value.lastName,
        value.mobile
      )
      .then((res: any) => {
        console.log(res);
        setOpened(true)
        setLink(res);
      });
  };

  const handleLoadSession = async () => {
    const result = await (window as any).startBureauSession();
    sessionStorage.setItem("data",data)
   
    const jsonData =JSON.parse(atob(data))
    console.log(data, 'data',jsonData )
    let passData: any ={}
    if(jsonData?.flow ==='apply-now'){
      passData = {...panData, fees:jsonData.fees,
                    instituteName: jsonData.instituteName, 
                    course: jsonData.course, channelId:jsonData.channelId, 
                    flow:jsonData?.flow,
                    application:jsonData?.applicationId,
                    userId:jsonData?.userId
                  }
    }
    else{
      passData={...panData, courseFees:jsonData?.courseFees,
         instituteName: jsonData.instituteName, course: jsonData.course, 
         channelId:jsonData.channelId, flow:jsonData?.flow}
    }
    if (result) {
      switch (result.status) {
       
        case "SUCCESS":
          try{
            const res = await axios.get( `${process.env.REACT_APP_DASHBOARD_URL}/rules/eligibility?phone=${userData?.mobile}`)
            const qecBody = {
              applicationId: userData.application,
              userId: userData.userId,
              instituteId: userData.instituteId,
              studentName: userData.firstName + " " +  userData.lastName  ,
              applicantName: userData.firstName + " " +  userData.lastName,
              panId: userData.panId,
              dob: moment(userData.dob).format("DD/MM/YYYY"),
              phone: userData.mobile,
              status: "Created",
              eligibility: res?.data?.data?.status,
            };
            if(res?.data?.data?.status === 3 || res?.data?.data?.status === 1){
              await axios.post(
                  `${process.env.REACT_APP_DASHBOARD_URL}/rules/create/eligibility`,
                  qecBody
                )
                .then((result: any) => {
                
                })
                .catch((err) => {
                  console.log(err);
                });

                navigate("/address-details", { state: { data:passData}});
            }
            else{
              navigate("/rejected-page", { state: { data: userData } });
            }
        
          }catch(e){
            console.log(e, 'eligibility err')
          }
          
          break;

        case "EXIT":
          alert("Retry Submit");
          handleCloseConsent();
          break;

        case "ERROR":
          alert("Error Please Try Later");
          handleCloseConsent();
          break;

        default:
          alert("Contact our team for assistance");
          break;
      }
    }
  };

  const getPanPro = () => {
    console.log("Clicked", userData);
    // TODO: Send longitude and latitude to the backend from here.

    // Latitude: {position.latitude}, Longitude: {position.longitude}

    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        console.log("Hello");
        console.log(userData, 'userData');

        createApplication({
          mobile: userData?.mobile,
          panId: pan,
          dob:moment(dob, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          channelId:userData?.channelId,
        }, '', userData?.flow)
          .then((result) => {
            if (result.message !== "Successful") {
              console.log("Error", result.message);

              setError(result.data || result.message);
              return;
            }
            if (result?.data === "Date of Birth not matching") {
              console.log("Error", result.data);

              setError(result.data);
              return;
            }
            
            setPanData(result.data)
            setUserData(result.data)
            handleStartSession(result.data)
           
          })
          .catch((err) => {
            console.log(err);
            setError("PAN not verified");
          });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
      alert("Please enable location services to proceed");
    }
  };
  
  return (
    <div className={styles.body}>
      {error && (
        <div
          style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100vh",
            backgroundColor: "rgba(0,0,0,0.6)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: "1000",
          }}
          onClick={() => setError("")}
        >
          <div
            style={{
              backgroundColor: "white",
              padding: "1rem 1rem",
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
              margin: "0 1rem",
              
            }}
            onClick={() => setError("")}
          >
            <h5
              style={{
                fontFamily: "Outfit",
                textAlign: "center",
                fontSize:'16px'
              }}
            >
              <span style={{color:'#d32028'}}>Error:{' '}</span>{error}
            </h5>
          </div>
        </div>
      )}
      <>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={openedModal}
          keepMounted
          closeAfterTransition
        >
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              display="flex"
              justifyContent="center"
              gap="40%"
              alignItems="center"
              style={{
                width: "371px",
                backgroundColor: "white",
                padding: "10px",
              }}
            >
              <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{
                  fontWeight: "500",
                  fontSize: "20px",
                }}
              >
                OTP Consent
              </Typography>
              <IconButton
                style={{ border: "2px solid #DbDbDb", width: "40px" }}
                aria-label="remove"
                size="small"
                onClick={handleCloseConsent}
              >
                <ClearIcon />
              </IconButton>
            </Box>

            <Box display="flex" justifyContent="center" alignItems="center">
              <iframe
                width="370"
                height="630"
                src={link}
                onLoad={handleLoadSession}
                title="bureau"
              ></iframe>
            </Box>
          </Box>
        </Modal>
      </>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.content}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                
              }}
            >
              <Label text="PAN number" left />
              <InputText
                square
                placeholder="PAN Number"
                changeHandler={(e) => setPan(e.target.value.toUpperCase())}
                value={pan}
              />
              <br />
              <Label text="Date Of Birth" left />
              <InputText
                square
                placeholder="Date of Birth"
                changeHandler={(e) => {
                  console.log(e.target.value);
                  setDob(e.target.value);
                }}
                value={dob}
                type="date"
              />
              <br />
            </div>
            <div></div>
            <div
              style={{
                display:'flex',
              }}
            >
              <input
                type="checkbox"
                onChange={(e: any) => handleCheck(e.target.checked)}
                style={{
                  marginRight: "10px",
                  height: "25px",
                  width: "25px",
                }}
                checked={checked}
              />
              <p className={styles.consentText}>
                I consent and authorize <FeemonkText /> to get a background
                check and a consumer credit report on me.
              </p>
            </div>
          </div>
          <Button
            onPress={() => {
              getPanPro();
            }}
            imageRight={ArrowLeft}
            text={"Verify"}
            disabled={!checked || !pan || !dob}
            insureFin={userData?.channelId === 5}
          />
          {/* <Button variant="contained" onClick={getPanPro}
            sx={{textTransform:'none', fontSize:'16px', backgroundColor:'#d32028', width:"20", ':hover':{opacity:0.8, backgroundColor:'#d32028',}}}
            size="large"
            disabled={!checked || !pan || !dob}
            >Verify</Button> */}
        </div>
        <br />
        <Footer />
      </div>
    </div>
  );
}

export default EnterPanIndividualPage;
