import React, { useState, useEffect } from "react";
import { useNavigate,useLocation  } from "react-router-dom";
import Button from "../../../components/atoms/Button";
import styles from "./index.module.css";
import ArrowRight from "../../../images/icons/arrow_right.svg";
import BackArrow from "../../../images/icons/arrow-left-circle.svg";
import bigcalendar from "../../../images/icons/bigcalendar.svg";
import bigemi from "../../../images/icons/bigemi.svg";
import emi from "../../../images/icons/emi.png"
import download from "../../../images/icons/download.svg";
import biginterest from "../../../images/icons/biginterest.svg";
import {jwtDecode} from "jwt-decode";
import axios from "axios";
import { formatToIndianRupees } from "../../../utils/formatToIndianRpuees";
import { getNextFifthDate } from "../../../utils/getNextFifthDate";
import selected from "../../../images/icons/selected.png"
import { ApplicantStage } from "../../../utils/applicationStage";
import Navbar from "../../../components/molecules/Navbar";
import  Checkbox  from "@mui/material/Checkbox";
import { FormControlLabel, Radio } from "@mui/material";
import Footer from "../../../components/molecules/Footer";
// Define the structure of summary data
interface SummaryData {
  mobile: string;
  userId: string;
  applicationId: string;
  courseFees:any
}

function ViewOffer() {
  const navigate = useNavigate();
  const [selectedDate, setSelectedDate] = useState("");
  const [summaryData, setSummaryData] = useState<SummaryData | null>(null);
  const [loading, setLoading] = useState(false)
  const location = useLocation();
  const stateData = location?.state?.data;
  const courseFees = stateData?.courseFees 
  console.log(stateData, 'view details page')
  const [products, setProducts] = useState([
    {
      tenure: 0,
    },
  ]);
  const [activeEmiPlan, setActiveEmiPlan] = useState("");
  const [emiAmount, setEmiAmount] = useState("");



  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const date = e.target.value;
    setSelectedDate(date);
  };

  const formatDate = (date: string) => {
    const [year, month, day] = date.split("-");
    return `${day}-${month}-${year}`;
  };

  const user = sessionStorage.getItem("auth_token") || "";
  
    let decode: any;
    let token: any
    if (user) {
      try {
        decode = JSON.parse(user).value;
        token = jwtDecode(JSON.parse(user).value) as any;
        console.log("token",token);
      } catch (error) {
        console.error("Error parsing user token:", error);
      }
    } else {
      console.error("Auth token not found");
    }
  // const decode = JSON.parse(user || "").value as any;
  console.log(decode);
  



  useEffect(() => {
    // Fetch summary data when the component mounts
    // const fetchSummaryData = async () => {
    //   try {
    //     const newApplicationId =  location?.state?.data?.existing ?  location?.state?.data?.applicationId : location?.state?.data?.application
    //     const response = await axios.get(`${process.env.REACT_APP_DASHBOARD_URL}/summary/user?id=${stateData?.userId}&applicationId=${newApplicationId}`, {
    //       headers: {
    //         Authorization: `Bearer ${decode}`,
    //       },
    //     });
    //     const { mobile, userId, applicationId,courseFees } = response.data.data;
    //     console.log(response.data.data, 'view offers summary')
    //     setSummaryData({mobile, userId, applicationId,courseFees})
    //   } catch (error) {
    //     console.error("Error fetching summary data:", error);
    //   }
    // };

    // fetchSummaryData();
    const { mobile, userId, applicationId,courseFees } = location?.state?.data || {};
    setSummaryData({mobile, userId, applicationId,courseFees})
  }, [location?.state?.data]);

  const approve = async (data: any) => {
    return fetch(`${process.env.REACT_APP_DASHBOARD_URL}/application/approve`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${decode}`,
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        return response.json();
      })
      .catch((error) => {
        return console.error(error);
      });
  };

  const handleButtonClick = async () => {
    setLoading(true)
    const updateStatusUrl = `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/assign-fund-source`;

    let emidate=new Date()
    let current=null
    if (emidate.getMonth() == 11) {
      current = `${emidate.getFullYear() + 1}/01/05`
    } else {
      current = `${emidate.getFullYear()}-${emidate.getMonth() + 2}-05`
    }
    const statusData = {
      applicationId: stateData.existing ? stateData?.applicationId:  stateData?.application ,
      fundCode: "GBP",
      productId: activeEmiPlan,
      loanAmount: courseFees,
      emi: Number(emiAmount),
      emiFirstDate:current,
      dayOfEmi: 5,
      rulesStatus:1
    };
    axios.post(`${updateStatusUrl}`, statusData, {
              headers: {
                Authorization: `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJhcHBsaWNhdGlvbnMtYmFja2VuZCIsInVzZXJfaWQiOjUsImVtYWlsIjoic2FpcmFta0BmZWVtb25rLmNvbSIsImlhdCI6MTcxNjg3Mzc4NjY5Nywicm9sZSI6ImNybSIsImRpc3BsYXlfbmFtZSI6IlNhaSBSYW0gUmVkZHkiLCJtb2JpbGUiOiI2MzA5MTQ4MzMzIiwiZXhwIjoxNzE2ODc0MzkxNDk3fQ.lPHnxTija-VpfqMzFWfJJ5fkHDkWJYM-kt2ybO7AgA8`,
                'Content-type': 'application/json',
              },
    })
      .then((res) => {
          if (res.data.message === 'Successful') {
             navigate("/bankstatement-evaluation", {
                state: { data: location?.state?.data },
              });
          
          }
        },
      ).catch((e)=>{
        console.log(e, 'assign-found-source-error')
      })

  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/products/instituteId?instituteId=B2C`)
      .then((response) => response.json())
      .then((data) => {
        setProducts(data.data);
      });
  }, []);

  useEffect(() => {
    const applicationId = stateData?.existing ?  stateData.applicationId : stateData?.application
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        // setStage(res.data.skipStatement)
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/pan-details/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/identify-yourself",{
                state: { data: location?.state?.data ,stage: res.data.skipStatement},
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data,stage: res.data.skipStatement },
              });
            
              break;
            case ApplicantStage.BankStatement:
              navigate("/view-offers", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              navigate("/select-emi", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Digilocker:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Selfie:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Agreement:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.BankAccountVerified:
              navigate("/sanctions", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/pan-details/:data`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, []);

  console.log(location?.state?.data, 'view offers location data')
  return (
    <div className={styles.body}>
       <Navbar />
      <div className={styles.container}>
          <div className={styles.main}>
          {/* <div className={styles.Header}>
            <button
              style={{ border: "none", background: "none" }}
              onClick={() => {
                navigate(-1);
              }}
            >
              <img
                style={{ marginLeft: "0.5rem", height: "1.5rem" }}
                src={BackArrow}
                alt=""
              />
            </button>
            <p style={{ marginRight: "0.5rem", fontWeight: "bold" }}>T&C</p>
          </div> */}
          <div
            style={{
              background: "#FFF7F2",
              border: "1px solid #F9D8D6",
              borderRadius: "12px 12px 0px 0px",
            }}
          >
            <p
              style={{
                fontSize: "1.5rem",
                textAlign: "center",
                fontWeight: "bold",
                marginTop:'12px'
              }}
            >
              ₹ {courseFees}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid #F9D8D6",
              background: "#ffffff",
              paddingTop:'12px',
              boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
              borderRadius: "0px 0px 12px 12px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "2rem",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <img
                  src={bigemi}
                  style={{ width: "40px",height:'40px', marginBottom: "0.5rem" }}
                />
                <p style={{ color: "#737373", fontSize: "0.875rem" }}>EMI</p>
                <p style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  {activeEmiPlan
                    ? `₹ ${formatToIndianRupees(
                        Number(
                          Number(
                            courseFees/
                              Number(
                                products
                                  .sort((a, b) => a.tenure - b.tenure)
                                  .find(
                                    (product: any) =>
                                      product.productId === activeEmiPlan
                                  ) &&
                                  products
                                    .sort((a, b) => a.tenure - b.tenure)
                                    .find(
                                      (product: any) =>
                                        product.productId === activeEmiPlan
                                    )?.tenure
                              )
                          ).toFixed(2)
                        )
                      )}`
                    : "₹ 0"}
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <img
                  src={bigcalendar}
                  style={{ width: "40px",height:'40px', marginBottom: "0.5rem" }}
                />
                <p style={{ color: "#737373", fontSize: "0.875rem" }}>TENURE</p>
                <p style={{ fontSize: "0.875rem", fontWeight: "bold" }}>
                  {activeEmiPlan
                    ? products
                        .sort((a, b) => a.tenure - b.tenure)
                        .find(
                          (product: any) => product.productId === activeEmiPlan
                        )?.tenure
                    : "0"}{" "}
                  Months
                </p>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  flex: 1,
                }}
              >
                <img
                  src={biginterest}
                  style={{ width: "40px",height:'40px',marginBottom: "0.5rem" }}
                />
                <p style={{ color: "#737373", fontSize: "0.875rem" }}>
                  Starts On
                </p>
                <p
                  style={{
                    fontSize: "0.875rem",
                    fontWeight: "bold",
                    textDecoration: "underline",
                    color: "#d23028",
                    cursor: "pointer",
                  }}
                  // onClick={() =>
                  //   document.getElementById("emiDateInput")?.focus()
                  // }
                >
                  {getNextFifthDate()}
                  {/* {selectedDate ? formatDate(selectedDate) : "Select Date"} */}
                </p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "column",
            }}
          >
            <p
              style={{
                textAlign: "left",
                fontSize: "1.2rem",
                fontWeight: "bold",
                marginTop:'1rem'
              }}
            >
              Choose EMI plan
            </p>
            <div
              
            >
              {products
                .sort((a, b) => a.tenure - b.tenure)
                .map((product: any) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding:'8px 10px',
                      border:
                        activeEmiPlan === product.productId
                          ? "1px solid #D32028"
                          : "1px solid #F9D8D6",
                      borderRadius: "12px",
                      marginBottom: "1rem",
                      background: "#FFF8F4",
                      boxShadow: "0px 3px 3px rgba(211, 32, 40, 0.1)",
                      cursor:'pointer'
                    }}
                    key={product.productId}
                    onClick={() => {
                      setActiveEmiPlan(product.productId);
                      setEmiAmount((courseFees / Number(product.tenure)).toFixed(2))
                    }}
                  >
                    <Radio color="error" checked={product.productId===activeEmiPlan} />
                    <img src={emi} style={{width:'40px'}}/>
                    <p
                      style={{
                        textAlign: "left",
                        fontSize: "1rem",
                        fontWeight: "bold",
                      }}
                    >
                      {formatToIndianRupees(
                        Number(
                          Number(courseFees / Number(product.tenure)).toFixed(2)
                        )
                      )}
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: "1rem",
                        // fontWeight: "bold",
                      }}
                    >
                      {product.tenure} Months
                    </p>
                    {/* {activeEmiPlan === product.productId ? <img src={selected}/>:null} */}
                  </div>
                ))}
            </div>
          </div>
          <br />
          <div style={{marginTop:'0.5rem', width:'40%', margin:'0 auto'
          }}> <Button
            text={"Continue"}
            onPress={handleButtonClick}
            imageRight={ArrowRight}
            disabled={!activeEmiPlan && !emiAmount }
            fullWidth
          /></div>
         
        </div>
       
        {/* <BottomNavigationBar active="Home"/> */}
      </div>
      <Footer/>
    </div>
  );
}

export default ViewOffer;
