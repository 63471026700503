import "./App.css";
import Mainpage from "./Components/Mainpage/Index";
import Home from "./Components/Home/Index";
import { Provider } from "react-redux";
import { store } from "./helpers/store";

// import EnterPan from "./pay-later/pages/EnterPan";
import Conditions from "./Conditions";
import EnterPan from "./pay-later/pages/EnterPan";
import ValidatingPan from "./pay-later/pages/ValidatingPan";
import Sanctions from "./pay-later/pages/Sanction";
import Address from "./pay-later/pages/Address";
import Approval from "./pay-later/pages/Approval";
import WorkDetails from "./pay-later/pages/WorkDetails";
import CourseDetails from "./pay-later/pages/CourseDetails";
import Congratulations from "./pay-later/pages/Congratulations";
import SelectDate from "./pay-later/pages/SelectDate";
import SanctionLetter from "./pay-later/pages/SanctionLetter";
import SelectEmi from "./pay-later/pages/SelectEmi";
import Selfie from "./pay-later/pages/Sanction/selfie";
import Digilocker from "./pay-later/pages/Sanction/digilocker";
import Mandate from "./pay-later/pages/Sanction/mandate";
import Agreement from "./pay-later/pages/Sanction/agreement";
import Loandetails from "./Components/Mainpage/Loandetails";
import ApplicationDetails from "./Components/Mainpage/ApplicationsDetails";
import Repaymentdetails from "./Components/Mainpage/Repaymentdetails";
import Noactiveloans from "./Components/Mainpage/Noactiveloans";
import Noactiverepayments from "./Components/Mainpage/Noactiverepayments";
import FeeManagement from "./feeManagement";
import UpFrontFee from "./upFrontFee";
import PayFees from "./payFees";
import Terms from "./Terms";
import Consent from "./Consent";
import Privacy from "./Privacy";
import Main from "./main";
import AOS from "aos";
import Bstmt from "./bstmt";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DataProvider } from "./pay-later/context/DataContext";
import AccountAggregator from "./pay-later/pages/AccountAgregator";
import NetBanking from "./pay-later/pages/AccountAgregator/pdfBankStatement";
import BankStatement from "./pay-later/pages/AccountAgregator/pdfBankStatement";
import Emihistory from "./Components/Mainpage/Emihistory";
import AdminLogin from "./Components/Home/AdminLogin";
import AccountAggregatorSuccess from "./pay-later/pages/AccountAgregator/success";
import ParkingPage from "./pay-later/pages/ParkingPage";
import DisbursedPage from "./pay-later/pages/Disbursed";
import PFCollection from "./pay-later/pages/PFCollection";
import RejectedPage from "./pay-later/pages/Rejected";
import ParkingPageRejected from "./pay-later/pages/ParkingPage/rejected";

import BankStatementUploadPage from "./pay-later/pages/BankStatementUpload";
import BankDetailsInfoPage from "./pay-later/pages/BankDetailsInfo";

import BankStatementEvaluation from "./pay-later/pages/BankStatementEvaluate";

// Individual pan, address and work details

import EnterPanIndividualPage from "./pay-later/pages/IndividualPanAddressWorkdetails/EnterPanDetails";
import AddressIndividualPage from "./pay-later/pages/IndividualPanAddressWorkdetails/AddressDetails";
import WorkDetailsIndividualPage from "./pay-later/pages/IndividualPanAddressWorkdetails/WorkDetails";
import BankStatementUpload from "./pay-later/pages/IndividualPanAddressWorkdetails/BankStatementUpload";
import ViewOffers from "./pay-later/pages/IndividualPanAddressWorkdetails/ViewOffer";
import IdentifyYourSelf from "./pay-later/pages/IndividualPanAddressWorkdetails/IdentifyYourSelf";
import StudentDetails from "./pay-later/pages/IndividualPanAddressWorkdetails/StudentDetails";

function App() {
  AOS.init();
  return (
    <>
      <Provider store={store}>
        <DataProvider>
          <Router>
            <Routes>
              <Route exact path="/login" element={<Home />} />
              <Route exact path="/login/admin" element={<AdminLogin />} />
              <Route exact path="/mainpage" element={<Mainpage />} />
              {/* <Route path="/:data" element={<EnterPan />} /> */}
              <Route path="/validating" element={<ValidatingPan />} />
              <Route path="/sanctions" element={<Sanctions />} />  {/*stage 4, 9,10,11, 12,13 */}
              <Route path="/sanctions/digilocker" element={<Digilocker />} />
              <Route path="/sanctions/selfie" element={<Selfie />} />
              <Route path="/sanctions/agreement" element={<Agreement />} />
              <Route path="/sanctions/mandate" element={<Mandate />} />
              {/*<Route path="/address" element={<Address />} /> */} {/* stage 1 */}
              <Route path="/approval" element={<Approval />} />
              {/*<Route path="/work-details" element={<WorkDetails />} /> */} {/* stage  2*/}
              <Route path="/course-details" element={<CourseDetails />} />
              <Route path="/congratulations" element={<Congratulations />} />
              <Route path="/parking-page" element={<ParkingPage />} /> {/* stage 4*,5,6,7*/}
              <Route path="/parking-page-rejected" element={<ParkingPageRejected />}/>
              <Route path="/select-emi" element={<SelectEmi />} />  {/* stage 3,8 */}
              <Route path="/select-date" element={<SelectDate />} />
              <Route path="/sanction-letter" element={<SanctionLetter />} />
              <Route path="/disbursed-page" element={<DisbursedPage />} />  {/* stage 5, 14 ,16*/}
              <Route path="/pf-collection" element={<PFCollection />} /> {/* stage 15*/}
              <Route path="/rejected-page" element={<RejectedPage />} /> {/* stage 18 */}
              <Route path="/aa-success" element={<AccountAggregatorSuccess />} />
              <Route exact path="/mainpage/loandetails"  element={<Loandetails />}/>
              <Route exact path="/mainpage/loandetails/emihistory" element={<Emihistory />} />
              <Route exact path="/mainpage/applicationDetails"  element={<ApplicationDetails />}/>
              <Route exact path="/mainpage/repaymentdetails"  element={<Repaymentdetails />} />
              <Route exact path="/mainpage/noactiveloans"  element={<Noactiveloans />} />
              <Route exact path="/mainpage/noactiverepayments"  element={<Noactiverepayments />} />
              <Route path="/" element={<Main />} />
              <Route path="/feeManagement" element={<FeeManagement />} />
              <Route path="/bstmt" element={<Bstmt />} />

              <Route path="/account-aggregator"  element={<AccountAggregator />} />
              <Route path="/account-aggregator/bank-statement"  element={<BankStatement />} />

              {/* <Route path="/payments" element= {<MyPayments/>} /> */}
              <Route path="/upfrontFees" element={<UpFrontFee />} />
              <Route path="/payFees" element={<PayFees />} />
              <Route path="/apply/terms" element={<Terms />} />
              <Route path="/consent" element={<Consent />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms-conditions" element={<Conditions />} />

              <Route path="/bankstatement-upload" element={<BankStatementUploadPage />} />

              <Route path = "/bankdetails-info"  element ={<BankDetailsInfoPage />} />

              <Route path = "/bankstatement-evaluation" element = {<BankStatementEvaluation/>} />

              {/* individual Page routes */}
              <Route path = "/pan-details/:data" element = {<EnterPanIndividualPage/>} />
              <Route path = "/address-details" element = {<AddressIndividualPage/>} />
              <Route path = "/work-details" element = {<WorkDetailsIndividualPage/>} />
              <Route path="/bank-statement-upload" element={<BankStatementUpload />} />
              <Route path="/view-offers" element={<ViewOffers />} />
              <Route path="/identify-yourself" element={<IdentifyYourSelf />} />
              <Route path="/student-details" element={<StudentDetails />} />
              
            </Routes>
          </Router>
        </DataProvider>
      </Provider>
    </>
  );
}

export default App;
